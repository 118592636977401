import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { CustomButton } from '../../../../../components';
import { uploadImage, UploadPath } from '../../../../../apis/tournament';

import './InfoImageUpload.scss'

interface InfoImageUploadProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    imgUrl: string | null;
    title?: string;
    settingData: { width?: string; height?: string; name: 'img' | 'icon' | string };
    savePath?: UploadPath;
    children?: JSX.Element;
}

export const InfoImageUpload: FC<InfoImageUploadProps> = ({
    setFieldValue,
    imgUrl,
    title,
    settingData,
    savePath,
    children,
}) => {
    const { t } = useTranslation();

    const prefix = 'creativeDetail-';
    const buttonPrefix = 'button-';

    const onChange = (imageList: ImageListType) => {
        const formData = new FormData();

        formData.append('file', imageList[0]?.file || '');
        imageList[0].file &&
            uploadImage(formData, savePath || 'tournament')
                .then((data) => {
                    setFieldValue(settingData.name, data.url);
                })
                .catch((e) => {
                    console.log(e);
                });
    };
    //TODO validation upload
    return (
        <ImageUploading
            // resolutionType="ratio"
            // resolutionWidth={1024}
            // resolutionHeight={1024}
            // onError={(errors, files) => {
            //     console.log(errors, files);
            // }}
            value={[]}
            onChange={onChange}
        >
            {({ onImageUpload }) => {
                return (
                    <div className="d-flex flex-column align-items-sm-center align-items-lg-start raqt-upload__wrapper">
                        <div className="d-flex flex-column w-sm-25 w-lg-100 align-items-center raqt-upload__image-wrapper">
                        <div>
                            {title && <label htmlFor={settingData.name} className="font-weight-normal">
                                {title}
                            </label>}
                            {imgUrl ? (
                                <div
                                    className={`text-center ${
                                        settingData.name === 'img'
                                            ? 'imgContainer'
                                            : 'iconContainer'
                                    }`}
                                >
                                    <img
                                        width={settingData.width || ''}
                                        height={settingData.height || ''}
                                        src={imgUrl}
                                        className={`${
                                            settingData.name === 'img'
                                                ? 'rounded'
                                                : 'rounded-circle'
                                        } d-block`}
                                        alt={
                                            settingData.name === 'img'
                                                ? 'tournament image'
                                                : 'tournament icon'
                                        }
                                        style={{ objectFit: 'cover' }}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={`${
                                        settingData.name === 'img'
                                            ? 'imgContainer'
                                            : 'iconContainer'
                                    } d-flex justify-content-center align-items-center bg-dark text-white mb-2`}
                                >
                                    img
                                </div>
                            )}
                        </div>

                            {!children && <CustomButton
                                btnText={`${t(buttonPrefix + 'change')} ${
                                    settingData.name === 'img'
                                        ? t(prefix + 'image').toLowerCase()
                                        : t(prefix + 'icon').toLowerCase()
                                }`}
                                cb={onImageUpload}
                            />}

                            {children && <div onClick={onImageUpload} role="button">
                                            {children}
                                        </div>
                            }
                        </div>
                    </div>
                );
            }}
        </ImageUploading>
    );
};
