export enum ModalAction {
    ADD_MEMBER = 'ADD_MEMBER',
    ADD_CAPTAIN = 'ADD_CAPTAIN',
    ADD_TOURNAMENT_CLASS = 'ADD_TOURNAMENT_CLASS',
    ADD_GROUPS_PLAY = 'ADD_GROUPS_PLAY',
    ADD_MATCH_TEAM = 'ADD_MATCH_TEAM',
    ADD_MATCH_PLAYER = 'ADD_MATCH_PLAYER',
    EDIT_MATCH_RESULT = 'EDIT_MATCH_RESULT',
    DIALOG_WINDOW = 'DIALOG_WINDOW',
    KEYWORD_WINDOW = 'KEYWORD_WINDOW',
    SPONSOR_WINDOW = 'SPONSOR_WINDOW',
    PADDLE_WINDOW = 'PADDLE_WINDOW',
    TOUR_WINDOW = 'TOUR_WINDOW',
}

export enum ModalName {
    ADD_TOURNAMENT_MEMBER = 'ADD_TOURNAMENT_MEMBER',
    ADD_TEAM_PLAYERS_CLASS = 'ADD_TEAM_PLAYERS_CLASS',
    ADD_TEAM_CAPTAIN_CLASS = 'ADD_TEAM_CAPTAIN_CLASS',
    ADD_CREATIVES_MEMBER = 'ADD_CREATIVES_MEMBER',
}
