import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import { useAppSelector } from '../../../../../hooks/redux';
import {
    IMatchScheduleItem,
    IScheduleDayConstraints,
    IScheduleItem,
    isFreeSlot,
} from '../../../../../types/schedule';
import { styleWhenSomeItemIsSelected } from './item.helpers';

interface ScheduleItemPositionerProps {
    day: IScheduleDayConstraints;
    children: React.ReactElement;
}

export function ScheduleItemPositioner({ day, children }: ScheduleItemPositionerProps) {
    const { scheduleItemScaleMultiplier, selectedItem, isFreeEditMode } =
        useAppSelector(selectSchedule);

    const item = (children.props as { item: IScheduleItem }).item;

    const length = isFreeSlot(item)
        ? day!.defaultMatchLength + day!.defaultPauseLength
        : day!.classOptions.find(
              (option) => option.class.id === (item as IMatchScheduleItem).classId
          )!.matchLengths[(item as IMatchScheduleItem).type] + day!.defaultPauseLength;

    return (
        <div
            className={
                'card shadow-sm ' + styleWhenSomeItemIsSelected(item, selectedItem, isFreeEditMode)
            }
            style={{
                height: length * scheduleItemScaleMultiplier,
                overflow: 'hidden',
            }}
        >
            {children}
        </div>
    );
}
