import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormTextField } from '../../../../../components';
import { initialData } from '../../../../../constant/tourDetail';
import { InfoSection } from '../InfoSection';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { getStringDateFormat } from '../../../../../utils/getStringDate';
import { infoTagTypeConverter } from '../../../../../utils/infoTagTypeConverter';
import { updateTournamentInfo } from '../../../../../features/tournament/actionCreators';
import { InfoImageUpload } from '../InfoImageUpload';
import { selectTournament } from '../../../../../features/tournament/tournamentSelectors';
import { TagIdsEnum } from '../../../../../constant/navigation';

import './InformationTag.scss';
import { useParams } from 'react-router-dom';

export type Section = {
    readonly id?: string | number;
    title: string;
    description: string | null;
};

export interface Values {
    name: string;
    description: string | null;
    startDate: string | null;
    endDate: string | null;
    lastCancelationDate: string | null;
    registrationOpen: string | null;
    registrationClose: string | null;
    registrationFee: number | null;
    sections: Array<Section>;
    img: null | string;
    icon: null | string;
}

export const InformationTag = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const prefixValidation = 'validation-';
    const { communityId } = useParams<{ communityId: string }>();

    const TourDetailSchema = Yup.object().shape({
        name: Yup.string().max(40, t(prefixValidation + 'tooLong')),
        description: Yup.string().max(140, t(prefixValidation + 'tooLong')),
        startDate: Yup.date().required(t(prefixValidation + 'required')).typeError(t(prefixValidation + 'date')),
        endDate: Yup.date().required(t(prefixValidation + 'required')).typeError(t(prefixValidation + 'date')),
        lastCancelationDate: Yup.date().required(t(prefixValidation + 'required')).typeError(t(prefixValidation + 'date')),
        registrationOpen: Yup.date().required(t(prefixValidation + 'required')).typeError(t(prefixValidation + 'date')),
        registrationClose: Yup.date().required(t(prefixValidation + 'required')).typeError(t(prefixValidation + 'date')),
        registrationFee: Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .positive(t(prefixValidation + 'positive')),
        sections: Yup.array().of(Yup.object().shape({ title: Yup.string().required(t(prefixValidation + 'required')) })),
    });

    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectTournament);

    const formikInitialState = data
        ? {
              name: data.name,
              description: data.description || '',
              startDate: getStringDateFormat(data.startDate),
              endDate: getStringDateFormat(data.endDate),
              lastCancelationDate: getStringDateFormat(data.lastCancelationDate),
              registrationOpen: getStringDateFormat(data.registrationOpen),
              registrationClose: getStringDateFormat(data.registrationClose),
              registrationFee: data.registrationFee || 0,
              sections: data.sections.map((section) =>
                  section.description === null ? { ...section, description: '' } : section
              ),
              img: data.img,
              icon: data.icon,
          }
        : { ...initialData };


    return (<>
        <div className="information-tag px-2">
            <Formik
                enableReinitialize={true}
                validationSchema={TourDetailSchema}
                initialValues={formikInitialState}
                onSubmit={(values: Values) => {
                    data && dispatch(updateTournamentInfo(data.id, +communityId, infoTagTypeConverter(values)));
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form autoComplete="off" id={TagIdsEnum.INFORMATION}>
                        <div className="my-3">
                            <h4 className="font-weight-bold">{t(prefix + 'details')}</h4>
                        </div>
                        <div className="container-fluid px-0">
                            <div className="row">
                                <div className="col-lg-4 d-sm-block">
                                    <FormTextField
                                        type="text"
                                        placeholder={t(inputPrefix + 'placeholderName')}
                                        id="name"
                                        label={t(prefix + 'tournamentName')}
                                        name="name"
                                    />
                                    <FormTextField
                                        type="description"
                                        label={t(prefix + 'tournamentDescr')}
                                        name="description"
                                        id="description"
                                        placeholder={t(inputPrefix + 'placeholderDescr')}
                                    />
                                </div>
                                <div className="col">
                                    <div className="w-100 d-flex flex-wrap justify-content-between ">
                                        <div className="dateInputContainer pe-2 pe-lg-0">
                                            <FormTextField
                                                type="datetime-local"
                                                id="startDate"
                                                label={t(prefix + 'startDate')}
                                                name="startDate"
                                            />
                                        </div>
                                        <div className="dateInputContainer ps-2 ps-lg-0">
                                            <FormTextField
                                                type="datetime-local"
                                                id="endDate"
                                                label={t(prefix + 'endDate')}
                                                name="endDate"
                                            />
                                        </div>
                                        <div className="dateInputContainer pe-2 pe-lg-0">
                                            <FormTextField
                                                type="datetime-local"
                                                id="lastCancelationDate"
                                                label={t(prefix + 'cancelation')}
                                                name="lastCancelationDate"
                                            />
                                        </div>
                                        <div className="dateInputContainer ps-2 ps-lg-0">
                                            <FormTextField
                                                type="datetime-local"
                                                id="registrationOpen"
                                                label={t(prefix + 'regOpen')}
                                                name="registrationOpen"
                                            />
                                        </div>
                                        <div className="dateInputContainer pe-2 pe-lg-0">
                                            <FormTextField
                                                type="datetime-local"
                                                id="registrationClose"
                                                label={t(prefix + 'regClose')}
                                                name="registrationClose"
                                            />
                                        </div>
                                        <div className="dateInputContainer ps-2 ps-lg-0">
                                            <FormTextField
                                                placeholder="€0"
                                                type="text"
                                                id="registrationFee"
                                                label={t(prefix + 'regFee')}
                                                name="registrationFee"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <h4 className="font-weight-bold">{t(prefix + 'image')}</h4>
                        </div>
                        <div className="container-fluid px-0">
                            <div className="row">
                                <div className="col-lg-4 d-sm-block my-2">
                                    <div className="d-flex flex-column align-items-sm-center align-items-lg-start">
                                        <p className="w-100">{t(prefix + 'imageWarning')}</p>
                                        <div className="d-flex flex-column w-sm-25 w-lg-100 align-items-center">
                                            <InfoImageUpload
                                                setFieldValue={setFieldValue}
                                                imgUrl={values.icon}
                                                settingData={{
                                                    width: '100',
                                                    height: '100',
                                                    name: 'icon',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col my-2">
                                    <InfoImageUpload
                                        setFieldValue={setFieldValue}
                                        imgUrl={values.img}
                                        settingData={{ height: '353', width: '353', name: 'img' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid px-0">
                            <InfoSection data={values.sections} />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </>);
};
