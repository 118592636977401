import { FC } from 'react';
import { FieldProps, FormikProps } from 'formik';
import classnames from 'classnames';

import IntlTelInput from 'react-intl-tel-input-18';

import './react-intl-tel-input-18.main.css';

import './PhoneInput.scss';
import { useTranslation } from 'react-i18next';

interface PhoneInputProps {
    field: FieldProps,
    form: FormikProps<any>,
    label: string
}

export const PhoneInput: FC<PhoneInputProps> = ({ field, form, label, ...props }) => {
    const prefix = 'validation-'

    const { t } = useTranslation();
    const error = form.getFieldMeta((field as any).name)?.error;

    const handlePhoneNumberChange = (...arg: [unknown, unknown, unknown, string]) => {
        const [,,, formattedPhoneNumber] = arg;

        (field as any).name && form.setFieldValue((field as any).name, formattedPhoneNumber.replace(/\s+/g, ''));
    };

    const handlePhoneNumberOnBlur = (valid: boolean, value: string) => {
        !valid && !error && value && form.setFieldError((field as any).name, t(prefix + 'phoneInvalid'));
    };
    
    return <div className={classnames('raqt__phone-input', {
        'is-invalid': !!error
    })}>
        {label && (
            <label htmlFor={(field as any).name} className="font-weight-normal">
                {label}
            </label>
        )}
        <IntlTelInput
            {...props}
            format={true}
            style={{ width: "100%" }}
            onPhoneNumberChange={handlePhoneNumberChange}
            onPhoneNumberBlur={handlePhoneNumberOnBlur}
            defaultValue={(field as any).value}
        />
        <div className="field-error text-danger fs-7">{error || null}</div>
    </div> 
};