import uniqId from 'uniqid';

export const initialData = {
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    lastCancelationDate: '',
    registrationOpen: '',
    registrationClose: '',
    registrationFee: 0,
    sections: [{ id: uniqId(), title: '', description: '' }],
    img: null,
    icon: null,
};
