import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Class, TournamentState } from '../../types/tournament';
import {
    IFreeSlotItem,
    IMatchScheduleItem,
    IScheduleConstraints,
    IScheduleDayConstraints,
    ITournamentSchedule,
} from '../../types/schedule';
import { FilterItem } from '../../pages/tourDetails/Schedule/components/schedule.helpers';

type State = {
    tournamentInfo: TournamentState | null;
    classIdToObject: Record<string, Class>;
    scheduleConstraints: IScheduleConstraints | null;
    scheduleConstraintsGiven: boolean;
    schedule: ITournamentSchedule | null;
    unscheduledMatches: IMatchScheduleItem[];
    displaySchedule?: ITournamentSchedule;
    selectedItem: IMatchScheduleItem | IFreeSlotItem | null;
    currentFilters: FilterItem[];
    message: string;
    error: string;
    isLoading: boolean;
    isCreatingSchedule: boolean;
    updatingSchedule: boolean;
    isFreeEditMode: boolean;
    loadedSavedSchedule: boolean;
    scheduleItemScaleMultiplier: number;
};

const initialState: State = {
    tournamentInfo: null,
    classIdToObject: {},
    scheduleConstraints: null,
    scheduleConstraintsGiven: false,
    schedule: null,
    unscheduledMatches: [],
    selectedItem: null,
    currentFilters: [],
    message: '',
    error: '',
    isLoading: false,
    isCreatingSchedule: false,
    updatingSchedule: false,
    isFreeEditMode: false,
    loadedSavedSchedule: false,
    scheduleItemScaleMultiplier: 10,
};

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        initialDataFetching: (state) => {
            state.isLoading = true;
        },

        initialDataFetchSuccess: (state) => {
            state.isLoading = false;
        },

        initialDataFetchError: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        setTournamentInfo: (state, action: PayloadAction<TournamentState>) => {
            state.tournamentInfo = action.payload;

            state.classIdToObject = state.tournamentInfo!.classes.reduce(
                (accumulator, currentClass) => {
                    accumulator[currentClass.id] = currentClass;
                    return accumulator;
                },
                {} as Record<string, Class>
            );
        },

        setLoadedSavedSchedule: (state) => {
            state.loadedSavedSchedule = true;
        },

        creatingSchedule: (state) => {
            state.isCreatingSchedule = true;
        },

        creatingScheduleSuccess: (state) => {
            state.isCreatingSchedule = false;
        },

        creatingScheduleError: (state, action: PayloadAction<string>) => {
            state.isCreatingSchedule = false;
            state.error = action.payload;
        },

        setScheduleConstraints: (state, action: PayloadAction<IScheduleConstraints>) => {
            state.scheduleConstraints = action.payload;
        },

        updateScheduleConstraintDays: (state, action: PayloadAction<IScheduleDayConstraints[]>) => {
            if (state.scheduleConstraints !== null) {
                state.scheduleConstraints.days = action.payload;
                state.scheduleConstraintsGiven = true;
            }
        },

        setScheduleConstraintsGiven: (state, action: PayloadAction<boolean>) => {
            state.scheduleConstraintsGiven = action.payload;
        },

        setScheduleState: (state, action: PayloadAction<ITournamentSchedule>) => {
            state.schedule = action.payload;
        },

        setUnscheduledMatchesState: (state, action: PayloadAction<IMatchScheduleItem[]>) => {
            state.unscheduledMatches = action.payload;
        },

        setDisplayScheduleState: (state, action: PayloadAction<ITournamentSchedule>) => {
            state.displaySchedule = action.payload;
        },

        setSelectedItem: (
            state,
            action: PayloadAction<IMatchScheduleItem | IFreeSlotItem | null>
        ) => {
            state.selectedItem = action.payload;
        },

        setCurrentFilters: (state, action: PayloadAction<FilterItem[]>) => {
            state.currentFilters = action.payload;
        },

        updateSchedule: (state) => {
            state.updatingSchedule = true;
        },

        updateScheduleSuccess: (state) => {
            state.updatingSchedule = false;
            state.error = '';
            state.message = 'toast-scheduleUpdated';
        },

        updateScheduleError: (state, action: PayloadAction<string>) => {
            state.updatingSchedule = false;
            state.error = action.payload;
            state.message = '';
        },

        scheduleClearNotification: (state) => {
            state.error = '';
            state.message = '';
        },

        setFreeEditMode: (state, action: PayloadAction<boolean>) => {
            state.isFreeEditMode = action.payload;
        },

        setScheduleItemScaleMultiplier: (state, action: PayloadAction<number>) => {
            state.scheduleItemScaleMultiplier = action.payload;
        },

        clearScheduleStates: (state) => {
            state.scheduleConstraintsGiven = false;
            state.schedule = null;
            state.unscheduledMatches = [];
            state.selectedItem = null;
            state.currentFilters = [];
            state.isFreeEditMode = false;
            state.scheduleItemScaleMultiplier = initialState.scheduleItemScaleMultiplier;
        },

        restoreInitialState: (state) => {
            state.tournamentInfo = initialState.tournamentInfo;
            state.classIdToObject = initialState.classIdToObject;
            state.scheduleConstraints = initialState.scheduleConstraints;
            state.scheduleConstraintsGiven = initialState.scheduleConstraintsGiven;
            state.schedule = initialState.schedule;
            state.unscheduledMatches = initialState.unscheduledMatches;
            state.selectedItem = initialState.selectedItem;
            state.currentFilters = initialState.currentFilters;
            state.message = initialState.message;
            state.error = initialState.error;
            state.isLoading = initialState.isLoading;
            state.isCreatingSchedule = initialState.isCreatingSchedule;
            state.updatingSchedule = initialState.updatingSchedule;
            state.isFreeEditMode = initialState.isFreeEditMode;
            state.scheduleItemScaleMultiplier = initialState.scheduleItemScaleMultiplier;
            state.loadedSavedSchedule = false;
            state.displaySchedule = undefined;
        },
    },
});

export const {
    initialDataFetching,
    initialDataFetchSuccess,
    initialDataFetchError,
    setTournamentInfo,
    setLoadedSavedSchedule,
    creatingSchedule,
    creatingScheduleSuccess,
    creatingScheduleError,
    setScheduleConstraints,
    updateScheduleConstraintDays,
    setScheduleConstraintsGiven,
    setScheduleState,
    setUnscheduledMatchesState,
    setDisplayScheduleState,
    setSelectedItem,
    setCurrentFilters,
    updateSchedule,
    updateScheduleSuccess,
    updateScheduleError,
    scheduleClearNotification,
    setFreeEditMode,
    setScheduleItemScaleMultiplier,
    clearScheduleStates,
    restoreInitialState,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
