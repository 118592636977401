import React, { FC, useState } from 'react';
import Select from 'react-select';
import { customStyles } from '../../components/modal/MemberModal';
import { Field, FieldProps, useField } from 'formik';
import { useAppDispatch } from '../../hooks/redux';
import { setCurrentType, setCurrentName } from '../../features/class/classSlice';
import { ConfirmModal } from '../modal/ConfirmModal';

interface Option {
    label: string;
    value: string | number;
}

interface ReactSelectFormProps {
    options: Option[];
    isMulti?: boolean;
    placeholder?: string;
    name: string;
    label?: string;
    labelPdd?: string;
    withConfirm?: boolean;
    disabled?: boolean;
    preselect?: boolean;
    onChangeCb?: (value: any) => void;
}

export const ReactSelectForm: FC<ReactSelectFormProps> = (props) => {
    const { options, name, placeholder, isMulti, label, labelPdd, withConfirm, disabled, onChangeCb, preselect = false } = props;

    const [ field , , helpers] = useField(name);

    const dispatch = useAppDispatch();
    const [confirmOpened, setConfirmOpened] = useState(false);
    const [selectedOption, setSelectedOption] = useState<Option | Option[] | null>(null);

    if(preselect && !field.value && options[0] && options[0]?.value) {
        helpers.setValue(options[0].value)
    }

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const onChange = (option: Option | Option[], force: boolean = false) => {
                    if(withConfirm && !force) {
                        setSelectedOption(option);
                        setConfirmOpened(true);
                    } else {
                        onChangeCb && onChangeCb(
                            isMulti
                            ? (option as Option[]).map((item) => item.value)
                            : (option as Option).value
                        );
                        form.setFieldValue(
                            field.name,
                            isMulti
                                ? (option as Option[]).map((item) => item.value)
                                : (option as Option).value
                        );
                        if (field.name === 'type' && !Array.isArray(option)) {
                            dispatch(setCurrentType(Number(option.value)));
                        }
                        if (field.name === 'classId' && !Array.isArray(option)) {
                            dispatch(setCurrentName(option.value));
                    }
                    }
                };
                
                const getValue = () => {
                    if (options) {
                        return isMulti
                            ? options.filter((option) => field.value.indexOf(option.value) >= 0)
                            : options.find((option) => option.value === field.value);
                    } else {
                        return isMulti ? [] : ('' as any);
                    }
                };

                const onConfirm = () => {
                    selectedOption && onChange(selectedOption, true);
                    setSelectedOption(null);
                };

                const onHide = () => setConfirmOpened(false);

                return (
                    <div className={labelPdd || 'py-3'}>
                        {label && (
                            <label htmlFor={name} className="font-weight-normal">
                                {label}
                            </label>
                        )}
                        <Select
                            className="holder-select"
                            defaultValue={options[0]}
                            value={getValue()}
                            onChange={(o) => onChange(o)}
                            name={field.name}
                            styles={customStyles}
                            options={options}
                            placeholder={placeholder}
                            isMulti={isMulti}
                            isDisabled={disabled}
                        />
                        {withConfirm && (<ConfirmModal 
                                            opened={confirmOpened}
                                            onConfirm={onConfirm}
                                            onHide={onHide}
                                        />)}
                    </div>
                );
            }}
        </Field>
    );
};
