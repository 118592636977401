import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import {
    setDisplayScheduleState,
    setSelectedItem,
    setCurrentFilters,
    clearScheduleStates,
} from '../../../../../features/schedule/scheduleSlice';
import { ITournamentSchedule } from '../../../../../types/schedule';
import { pureFilterScheduleBy, FilterItem, getHhmmTimeString } from '../schedule.helpers';
import { TagIdsEnum } from '../../../../../constant/navigation';
import { Form, Formik } from 'formik';
import {
    CustomButton,
    CustomSpinner,
    FormTextField,
    ReactSelectForm,
} from '../../../../../components';
import { UnscheduledMatches } from './UnscheduledMatches';
import { UnscheduledReport } from './UnscheduledReport';
import { ScheduleFilterType } from '../../../../../types/enums';
import { ScheduleItem } from './ScheduleItem';
import { BackIcon } from '../../../../../assets/icons/BackIcon';
import { FreeEditToggle } from './FreeEditToggle';
import { createScheduleAction } from '../../../../../features/schedule/scheduleActions';
import { RemoveScheduleButton } from './RemoveScheduleButton';
import { ScheduleItemPositioner } from './ScheduleItemPositioner';
import { ScheduleItemScaleSlider } from './ScheduleItemScaleSlider';

export const ScheduleLayout = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const dispatch = useAppDispatch();
    const {
        schedule,
        scheduleConstraints,
        displaySchedule,
        selectedItem,
        currentFilters,
        unscheduledMatches,
        isCreatingSchedule,
        loadedSavedSchedule,
    } = useAppSelector(selectSchedule);

    const addFilterItem = (formikValues: any) => {
        const type: ScheduleFilterType = formikValues['filter-select-mode'];
        const value: number = formikValues['filter-id-textfield'];

        const included = currentFilters.find((item) => item.type === type && item.value === value);

        if (!included) {
            dispatch(setCurrentFilters([...currentFilters, { type, value }]));
        }
    };

    const removeFilterItem = (item: FilterItem) => {
        dispatch(
            setCurrentFilters(
                currentFilters.filter(
                    (filterItem) =>
                        !(filterItem.type === item.type && filterItem.value === item.value)
                )
            )
        );
    };

    const initialFormikValues = {
        'filter-select-mode': ScheduleFilterType.CLASS,
        'filter-id-textfield': 0,
    };

    useEffect(() => {
        if (scheduleConstraints && schedule === null) {
            dispatch(createScheduleAction(t));
        }
    }, [dispatch, schedule, scheduleConstraints, t]);

    useEffect(() => {
        if (schedule) {
            let filteredSchedule: ITournamentSchedule = pureFilterScheduleBy(
                schedule,
                currentFilters
            );
            dispatch(setDisplayScheduleState(filteredSchedule));
        }
    }, [currentFilters, dispatch, schedule]);

    const deselectItemOnClickOutsideScheduleItems = () => {
        !!selectedItem && dispatch(setSelectedItem(null));
    };

    return (
        (isCreatingSchedule && <CustomSpinner />) || (
            <div onClick={deselectItemOnClickOutsideScheduleItems}>
                {schedule && scheduleConstraints && (
                    <>
                        <div className="d-flex justify-content-between">
                            <CustomButton
                                btnText=""
                                variant="outline-dark"
                                cb={() => dispatch(clearScheduleStates())}
                            >
                                <BackIcon w={'25'} h={'25'} />
                                {t(prefix + 'backToConstraints')}
                            </CustomButton>
                            {loadedSavedSchedule && <RemoveScheduleButton />}
                        </div>
                        <div>
                            <Formik initialValues={initialFormikValues} onSubmit={addFilterItem}>
                                {({ errors }) => (
                                    <Form autoComplete="off" id={TagIdsEnum.SCHEDULE}>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <ReactSelectForm
                                                    name="filter-select-mode"
                                                    options={[
                                                        {
                                                            label: t(
                                                                prefix + 'scheduleFilterClass'
                                                            ),
                                                            value: ScheduleFilterType.CLASS,
                                                        },
                                                        {
                                                            label: t(
                                                                prefix + 'scheduleFilterPlayer'
                                                            ),
                                                            value: ScheduleFilterType.PLAYER,
                                                        },
                                                    ]}
                                                />
                                            </div>
                                            <div className="col">
                                                <FormTextField
                                                    name="filter-id-textfield"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="">
                                                <CustomButton
                                                    btnText={t(prefix + 'applyFilterBtn')}
                                                    type="submit"
                                                    variant="outline-dark"
                                                    disabled={!!selectedItem}
                                                />
                                                {currentFilters.map((filterItem, index) => {
                                                    const filterValue =
                                                        filterItem.type === ScheduleFilterType.TIME
                                                            ? getHhmmTimeString(
                                                                  filterItem.value as string
                                                              )
                                                            : filterItem.value;
                                                    return (
                                                        <span key={index}>
                                                            <button
                                                                key={'button-' + index}
                                                                type="button"
                                                                onClick={() =>
                                                                    removeFilterItem(filterItem)
                                                                }
                                                            >
                                                                ⓧ {filterItem.type}:{filterValue}{' '}
                                                            </button>
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                            <div className="align-self-center">
                                                <ScheduleItemScaleSlider />
                                            </div>
                                            <div className="align-self-center">
                                                <FreeEditToggle />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        {unscheduledMatches.length > 0 && (
                            <div>
                                <h1>{t(prefix + 'unscheduledMatchesTitle')}</h1>
                                <UnscheduledReport />
                                <UnscheduledMatches />
                            </div>
                        )}
                        {displaySchedule &&
                            displaySchedule.days.map((day) => {
                                return (
                                    <>
                                        <h1 key={'day-' + day.dayKey}>Day {day.dayKey + 1}</h1>
                                        <div
                                            key={'container-day-' + day.dayKey}
                                            className="container-fluid border"
                                        >
                                            <div
                                                key={day.dayKey}
                                                className="row flex-nowrap overflow-auto"
                                            >
                                                {day.courts.map(({ court, matches }) => {
                                                    return (
                                                        <div
                                                            key={'column-' + court.id}
                                                            className="col-sm-auto col-6"
                                                        >
                                                            <h2 key={'court-' + court}>
                                                                {court.name}
                                                            </h2>

                                                            {matches.map((match, matchIndex) => {
                                                                return (
                                                                    <ScheduleItemPositioner
                                                                        day={
                                                                            scheduleConstraints.days.find(
                                                                                ({ dayKey }) =>
                                                                                    dayKey ===
                                                                                    day.dayKey
                                                                            )!
                                                                        }
                                                                    >
                                                                        <ScheduleItem
                                                                            item={match}
                                                                            key={matchIndex}
                                                                        />
                                                                    </ScheduleItemPositioner>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                    </>
                )}
            </div>
        )
    );
};
